<template>
    <form @submit.prevent="submitBody">
        <loading-overlay
            :active="apiStateLoading"
            :is-full-page="true"
            loader="bars"
        />
        <CModalExtended
            :title=title
            color="dark"
            size="xl"
            :show.sync="modalActive"
            :closeOnBackdrop="false"
            @update:show="$emit('close');"
        >

            <CRow>
                <CCol sm="12" lg="6"><!-- FIRTS COL-->
                    <CCol sm="12">
                        <CInput
                            v-uppercase
                            :label="$t('label.VersionCode')"
                            :placeholder="$t('label.VersionCode')"
                            :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                            addLabelClasses="required text-right"
                            v-model="$v.CodVersion.$model"
                            :is-valid="hasError($v.CodVersion)"
                            :invalid-feedback="errorMessage($v.CodVersion)"
                        />
                    </CCol>
                    <CCol sm="12">
                        <CSelect
                            :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                            addLabelClasses="text-right required"
                            :label="$t('label.typeApk')"
                            :options="TpModuleMobileListFormatted"
                            v-model.trim="$v.TpModuleId.$model"
                            :value.sync="TpModuleId"
                            :is-valid="hasError($v.TpModuleId)"
                            :invalid-feedback="errorMessage($v.TpModuleId)"
                        />
                    </CCol>
                    <CCol sm="12">
                        <CInput
                            class="text-table"
                            :label="$t('label.file')"
                            :placeholder="$t('label.fileName')"
                            :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                            addLabelClasses="required text-right"
                            v-model="$v.SigetMobileFile.$model"
                            :is-valid="hasError($v.SigetMobileFile)"
                            :invalid-feedback="errorMessage($v.SigetMobileFile)"
                        />
                    </CCol>
                    <CCol sm="12" v-if="isEdit">
                        <CSelect
                            :options="statusOptions"
                            :value.sync="Status"
                            :label="$t('label.status')"
                            :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                            addLabelClasses="required text-right"
                            :is-valid="statusSelectColor"
                        />
                    </CCol>
                    <CCol sm="12" lg="12">
                        <CRow class="mr-0">
                            <label class="col-form-label col-sm-12 col-lg-6 text-right pr-0 mb-2 pt-1">{{$t('label.MandatoryDownload')}}</label>
                            <CCol class="d-flex justify-content-end pr-0" sm="12" lg="auto">
                                <CSwitch
                                    color="watch"
                                    variant="3d"
                                    type="checkbox"
                                    :checked.sync="FgRequiredDonwload"
                                />
                            </CCol>
                        </CRow>
                    </CCol>
                </CCol>
                <CCol sm="12" lg="6"><!-- SECUND COLUMN-->
                   <CCol sm="12">
                        <vue-datepicker
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            :label="$t('label.AvailableDate')"
                            addInputClasses="pr-0"
                            class="w-100"
                            v-uppercase
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="AvailableTo"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                            valueType="format"
                        >
                            <template #input>
                                <CInput
                                  type="datetime"
                                  header
                                  :label="$t('label.AvailableDate')"
                                  addLabelClasses="required text-right"
                                  addInputClasses="pr-0"
                                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                                  class="w-80"
                                  v-uppercase
                                  placeholder="DD/MM/YYYY HH:mm"
                                  :value="AvailableTo"
                                  :is-valid="hasError($v.AvailableTo)"
                                  @blur="$v.AvailableTo.$touch()"

                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                        </vue-datepicker>
                   </CCol>
                   <CCol sm="12">
                        <vue-datepicker
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            :label="$t('label.ExpirationDate')"
                            addInputClasses="pr-0"
                            class="w-100"
                            v-uppercase
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="AvailableFrom"
                            :append-to-body="false"
                            :disabled-date="validateDateRange"
                            value-type="format"
                            :show-second="false"
                        >
                            <template #input>
                                <CInput
                                  type="datetime"
                                  header
                                  :label="$t('label.ExpirationDate')"
                                  addLabelClasses="text-right"
                                  addInputClasses="pr-0"
                                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                                  class=" w-80"
                                  v-uppercase
                                  placeholder="DD/MM/YYYY HH:mm"
                                  :value="AvailableFrom"
                                  :is-valid="hasError($v.AvailableFrom)"
                                  @blur="$v.AvailableFrom.$touch()"

                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                        </vue-datepicker>
                   </CCol>
                   <CCol sm="12">
                        <vue-datepicker
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            :label="$t('label.DeadLine')"
                            addInputClasses="pr-0"
                            class="w-100"
                            v-uppercase
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="RequiredTo"
                            :append-to-body="false"
                            :disabled-date="validateDateRangeRequiredTo"
                            value-type="format"
                            :show-second="false"
                        >
                            <template #input>
                                <CInput
                                  type="datetime"
                                  header
                                  :label="$t('label.DeadLine')"
                                  addLabelClasses="text-right"
                                  addInputClasses="pr-0"
                                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                                  class=" w-80"
                                  v-uppercase
                                  placeholder="DD/MM/YYYY HH:mm"
                                  :value="RequiredTo"
                                  :is-valid="hasError($v.RequiredTo)"
                                  @blur="$v.RequiredTo.$touch()"

                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                        </vue-datepicker>
                   </CCol>
               </CCol>
            </CRow>
            <br>
            <CRow >
                <CCol sm="12" lg="12">
                    <CCol sm="12">
                        <label class="required col-form-label col-sm-12 col-lg-auto text-right pr-0 pl-0 mb-1 pt-1">{{$t('label.description')}}</label>
                        <vue-editor
                            :placeholder="$t('label.description')"
                            :horizontal="{label:'col-sm-12 col-lg-12', input:'col-sm-12 col-lg-12'}"
                            v-model.trim="$v.FeaturesDescription.$model"
                            :is-valid="hasError($v.FeaturesDescription)"
                            :invalid-feedback="$t('label.required')"
                            required
                            :class="computedSelectDescription ? 'select-description--correct' : (verifyDescription ? 'select-description--error' : '') "
                            @blur="$v.FeaturesDescription.$touch()"
                        >
                        </vue-editor>
                    </CCol>
                    <CCol v-if="$v.FeaturesDescription.$error" sm="12" lg="3" class="pl-0 ml-0 mr-0">
                        <div class="col-sm-12 col-lg-6 text-danger mt-1 pr-0 mr-0 ml-0 text-left text-editor" v-if="!$v.FeaturesDescription.required">
                            {{$t('label.required')}}
                        </div>
                    </Ccol>
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    :disabled="isSubmit"
                    @click.stop="isEdit ? statusConfirmation(ApkItem.FgActVersion, Status, submitBody) : submitBody()"
                >
                <CIcon name="checkAlt"/>{{$t('button.accept')}}
                </CButton>
                <CButton
                    square
                    color="wipe"
                    class="d-flex align-items-center"
                    @click="$emit('close');"
                >
                <CIcon name="x" /><span class="ml-1"
                    >{{$t('button.cancel')}}</span
                >
                </CButton>
            </template>
        </CModalExtended>
    </form>
</template>

<script>
    import ModalMixin from '@/_mixins/modal';
    import General from "@/_mixins/general";
    import modalApk from '@/_validations/control-versiones/modal-apk';
    import { DateFormater } from '@/_helpers/funciones';
    import UpperCase  from '@/_validations/uppercase-directive';
    import vue2Dropzone from 'vue2-dropzone'
    import { VueEditor } from "vue2-editor";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    let user = JSON.parse(localStorage.getItem('user'));

    function data() {
        return {
            modalActive: false,
            isSubmit:false,
            SigetMobileVersionId:'',
            CodVersion:'',
            TpModuleId:'',
            TpModuleMobileItems:[],
            AvailableTo:'',
            AvailableFrom:'',
            RequiredTo:'',
            SigetMobileFile:'',
            previousDate: new Date(),
            laterDate: '',
            FgRequiredDonwload:false,
            FeaturesDescription:'',
            Status:0,
            apiStateLoading:false,
            verifyDescription: false,
        }
    }
    //methods
    function getTpModuleMobileList() {
        this.apiStateLoading = true;
        this.$http.get("TpModuleMobile-list")
        .then(response => {
            this.TpModuleMobileItems = response.data.data;
            this.apiStateLoading = false;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            this.apiStateLoading = false;
        });
    }

    function submitBody(){
        try {
            this.isSubmit = true;
            this.verifyDescription = true;
            this.$v.$touch();
            this.apiStateLoading = true;         

             if (this.$v.$error) {
                 this.isSubmit = false;
                 this.apiStateLoading = false;

                if (this.AvailableTo && this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                    throw this.$t('label.ErrorExpirationDateRangeAvalible');
                }
                if (this.RequiredTo && this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(this.RequiredTo)) {
                    throw this.$t('label.ErrorExpirationDateRangeDeadline');
                }
                if (this.AvailableTo && this.RequiredTo && DateFormater.formatDateTimeWithoutSlash(this.RequiredTo) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                    throw this.$t('label.ErrorDeadlineDateRangeAvailable');
                }
                if (this.FeaturesDescription === null || this.FeaturesDescription === '') {
                    throw this.$t('label.ErrorDescription');
                }
                throw this.$t('label.errorsPleaseCheck');
               
            }
    
            let AvailableToFormated = DateFormater.formatDateTimeWithoutSlash(this.AvailableTo);
            let AvailableFromFormated = this.AvailableFrom ? DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) :'';
            let RequiredToFormated = this.RequiredTo ? DateFormater.formatDateTimeWithoutSlash(this.RequiredTo) :'';
    
            const SigetMobileVersionJson = this.isEdit ? {
                SigetMobileVersionId:this.SigetMobileVersionId,
                TpModuleId: this.TpModuleId,
                CodVersion: this.CodVersion,
                FeaturesDescription: this.FeaturesDescription,
                AvailableTo: AvailableToFormated,
                AvailableFrom: AvailableFromFormated,
                RequiredTo:RequiredToFormated,
                FgRequiredDonwload: this.FgRequiredDonwload?1:0,
                SigetMobileFile: this.SigetMobileFile,
                Status: this.Status,
            }:{
                TpModuleId: this.TpModuleId,
                CodVersion: this.CodVersion,
                FeaturesDescription: this.FeaturesDescription,
                AvailableTo: AvailableToFormated,
                AvailableFrom: AvailableFromFormated,
                RequiredTo:RequiredToFormated,
                FgRequiredDonwload: this.FgRequiredDonwload?1:0,
                SigetMobileFile: this.SigetMobileFile
            };
            let metodo = this.isEdit ? 'PUT':'POST';
            let ruta = this.isEdit ? 'SigetMobileVersion-update':'SigetMobileVersion-insert';
            this.$http
            .ejecutar( metodo, ruta, SigetMobileVersionJson, { root: 'SigetMobileVersionJson' })
            .then((response) => {
                if (response && response.status === (200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.resetForm();
                    this.$emit('Update-list');
                    this.$emit('close');
                    this.apiStateLoading = false;
                    this.notifySuccess({text: messageSuccess})
                    this.isSubmit = false;
                }
            }).catch((e) => {
                this.isSubmit = false;
                this.apiStateLoading = false;
                this.notifyError({text: e});
            });
        } catch (e) {
            this.isSubmit = false;
            this.apiStateLoading = false;
            this.notifyError({text: e});
        }
    }


    function statusSelectColor() {
        return this.Status === 1;
    }

    function getdata(val) {
        this.SigetMobileVersionId=val.SigetMobileVersionId;
        this.TpModuleId = val.TpModuleId;
        this.CodVersion = val.CodVersion;
        this.FeaturesDescription = val.FeaturesDescription;
        this.AvailableTo = DateFormater.formatDateTimeWithSlash(val.AvailableTo);
        this.AvailableFrom = val.AvailableFrom? DateFormater.formatDateTimeWithSlash(val.AvailableFrom):'';
        this.RequiredTo = val.RequiredTo ? DateFormater.formatDateTimeWithSlash(val.RequiredTo):'';
        this.FgRequiredDonwload = val.FgRequiredDonwload;
        this.SigetMobileFile = val.SigetMobileFile
        this.Status = val.FgActVersion?1:0;
    
        this.$v.$touch();
    }

    function resetForm() {
        this.SigetMobileVersionId ='',
        this.TpModuleId = '';
        this.CodVersion='';
        this.FeaturesDescription = '';
        this.AvailableTo = DateFormater.formatDateTimeWithSlash(this.previousDate);
        this.AvailableFrom = '';
        this.RequiredTo = '';
        this.SigetMobileFile='';
        this.FgRequiredDonwload = false;
        this.verifyDescription = false;
        this.Status = 0;

        this.$v.$reset();
    }

    
    function validateDateRange(date) {
        return date < DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.AvailableTo));
    }
    function validateDateRangeRequiredTo(date) {
        if (this.AvailableFrom != '' ) {
            return date < DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) 
                || date > DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom));
        } else {
            return date < DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.AvailableTo));
        }
    }
    //computed
    function TpModuleMobileListFormatted(){
        //let _lang = this.$i18n.locale;
        if(this.TpModuleMobileItems.length === 0){
            return [{
            value: '',
            label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
            value: '',
            label: this.$t('label.select'),
            }]
            this.TpModuleMobileItems.map(function(e){
            chart.push({
                value: e.TpModuleId,
                label: e.TpModuleName
            })
            })
            return chart;
        }
    }

    function computedSelectDescription() {
        if (this.FeaturesDescription === null||this.FeaturesDescription === '') {
        return false;
        } else {
        return true;
        }
    }

    export default {
        name:'modal-apk',
        data,
        mixins: [General,ModalMixin],
        validations(){ 
             return modalApk((this.AvailableTo),this.AvailableFrom,this.RequiredTo)
        },
        //validations:modalApk,
        directives: UpperCase,
        components: {
            vueDropzone: vue2Dropzone,
            VueEditor
        },
        props: {modal: Boolean, isEdit: Boolean, ApkItem: Object, title: String,},
        methods:{
            getTpModuleMobileList, 
            submitBody,
            statusSelectColor,
            getdata,
            resetForm,
            validateDateRange,
            validateDateRangeRequiredTo,
        },
        computed:{
            TpModuleMobileListFormatted,
            computedSelectDescription
        },
        watch: {
            //modal functions
            modal: function (val) {
                this.modalActive = val;
                if (this.modalActive==true) {
                    this.getTpModuleMobileList();
                }
                if (this.isEdit==true) {
                    this.getdata(this.ApkItem);
                }else{
                    this.resetForm();
                };
            },
            AvailableTo: function (val) {
                try {
                    if (val!='') {
                        if (this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(val)) {
                            throw this.$t('label.ErrorExpirationDateRangeAvalible');
                        }
                        if (this.RequiredTo && this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(this.RequiredTo)) {
                            throw this.$t('label.ErrorExpirationDateRangeDeadline');
                        }
                        if (this.RequiredTo && DateFormater.formatDateTimeWithoutSlash(this.RequiredTo) <= DateFormater.formatDateTimeWithoutSlash(val)) {
                            throw this.$t('label.ErrorDeadlineDateRangeAvailable');
                        }
                    }
                } catch (error) {
                    this.notifyError({text: error});
                }
            },
            AvailableFrom: function (val) {
                try {
                    if (val!='') {
                        if (this.AvailableTo && DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                            throw this.$t('label.ErrorExpirationDateRangeAvalible');
                        }
                        if (this.RequiredTo && DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.RequiredTo)) {
                            throw this.$t('label.ErrorExpirationDateRangeDeadline');
                        }
                        if (this.AvailableTo && this.RequiredTo && DateFormater.formatDateTimeWithoutSlash(this.RequiredTo) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                            throw this.$t('label.ErrorDeadlineDateRangeAvailable');
                        }
    
                    }
                } catch (error) {
                    this.notifyError({text: error});
                }
                
            },
            RequiredTo: function (val) {
                try {
                    if (val!='') {
                        if (this.AvailableTo && this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                            throw this.$t('label.ErrorExpirationDateRangeAvalible');
                        }
                        if (this.AvailableFrom && DateFormater.formatDateTimeWithoutSlash(this.AvailableFrom) <= DateFormater.formatDateTimeWithoutSlash(val)) {
                            throw this.$t('label.ErrorExpirationDateRangeDeadline');
                        }
                        if (this.AvailableTo && DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.AvailableTo)) {
                            throw this.$t('label.ErrorDeadlineDateRangeAvailable');
                        }
                    }
                } catch (error) {
                    this.notifyError({text: error});
                }
            },
            FeaturesDescription:function (val) {
                if (this.FeaturesDescription==val ) {
                    this.verifyDescription=true; 
                }else{
                    this.verifyDescription=false;
                };
            },
        },
    }
</script>
<style lang="scss">

.select-description--correct {
    .ql-container{
        border-color: #2eb85c !important;
    }
    .ql-toolbar{
        border-color: #2eb85c !important;
    }
}
.select-description--error {
    .ql-container {
        border-color: #e55353 !important;
    }
    .ql-toolbar{
        border-color: #e55353 !important;
    }
}
.text-editor{
   font-size: 11.2px !important;
}

.text-table .form-control {
  text-transform: none !important;
}

</style>